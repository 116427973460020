// import numeral from "numeral";
// import React from "react";
// import { formatMonth } from "../../../utils/utils";

// const LabourAnalysis = ({
//   LabourInsightsHeader,
//   TotalActualLabourRev,
//   ActualLabourRevArr,
//   ActualLabourRev,
//   TotalActualLabourCost,
//   ActualLabourCostArr,
//   TotalGrossPAL,
//   GrossPAL,
//   TotalPerMargin,
//   PercentMargin,
//   Time_Sheet,
//   TotalForecastedLabourRevenue,
//   ForecastedLabourRevenue,
//   TotalLabourRevenueVariance,
//   LabourRevenueVariance,
//   TotalLabourRevenueVariancePer,
//   LabourRevenueVariancePer,
//   TotalActualLabourHours,
//   ActualLabourHours,
//   TotalLabourHoursTarget,
//   LabourHoursTarget,
//   TotalLabourHourLG,
//   LabourHourLG,
// }) => {
//   return (
//     <div className="LabourAnalysis">
//       <div className="TableNew">
//         <div className="TableFieldNames">
//           <div className="Item"></div>
//           <div className="Item">Actual Labour Revenue (% INV)</div>
//           <div className="Item">Actual Labour Cost</div>

//           <div className="Item">Gross Profit Attributable to Labour</div>
//           <div className="Item">% Margin</div>
//           <div className="Item divider"></div>

//           <div className="Item">Forecasted Labour Revenue</div>
//           <div className="Item">Labour Revenue Variance</div>
//           <div className="Item">Labour Revenue Variance (%)</div>

//           <div className="Item divider"></div>

//           <div className="Item">Actual Labour Hours</div>
//           <div className="Item">Labour Hours Target</div>
//           <div className="Item">Labour Hour Loss / Gain</div>
//           <div className="Item divider"></div>
//         </div>
//         <div className="TableFieldData">
//           <div className="DataTable Heading">
//             <div className="Item Total">Total</div>

//             {LabourInsightsHeader.map((x, i) => (
//               <>
//                 <div className="">-</div>
//                 <div className="Item">
//                   <div className="First">{formatMonth(x)}</div>
//                   <div className="Second">Month {i + 1}</div>
//                 </div>
//               </>
//             ))}
//           </div>
//           <div className="DataTable">
//             <div className="Item Total">
//               {numeral(TotalActualLabourRev).format("0,0")}
//             </div>

//             {ActualLabourRevArr.length > 0
//               ? ActualLabourRevArr.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{numeral(x).format("0,0")}</div>
//                   </>
//                 ))
//               : ""}
//           </div>
//           <div className="DataTable">
//             <div className="Item Total">
//               {numeral(TotalActualLabourCost).format("0,0")}
//             </div>
//             {ActualLabourCostArr.length > 0
//               ? ActualLabourCostArr.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{numeral(x).format("0,0")}</div>
//                   </>
//                 ))
//               : ""}
//           </div>
//           <div className="DataTable">
//             <div className="Item Total">
//               {numeral(TotalGrossPAL).format("0,0")}
//             </div>
//             {GrossPAL.length > 0
//               ? GrossPAL.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{numeral(x).format("0,0")}</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {TotalPerMargin.toFixed(0)}%
//             </div>
//             {PercentMargin.length > 0
//               ? PercentMargin.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{x.toFixed(0)}%</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="dividertable"></div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {numeral(TotalForecastedLabourRevenue).format("0,0")}
//             </div>
//             {ForecastedLabourRevenue.length > 0
//               ? ForecastedLabourRevenue.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{numeral(x).format("0,0")}</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {numeral(TotalLabourRevenueVariance).format("0,0")}
//             </div>
//             {LabourRevenueVariance.length > 0
//               ? LabourRevenueVariance.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{numeral(x).format("0,0")}</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {TotalLabourRevenueVariancePer.toFixed(0)}%
//             </div>
//             {LabourRevenueVariancePer.length > 0
//               ? LabourRevenueVariancePer.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{x.toFixed(0)}%</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="dividertable"></div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {TotalActualLabourHours.toFixed(0)}H
//             </div>
//             {ActualLabourHours.length > 0
//               ? ActualLabourHours.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{x.toFixed(0)}H</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {TotalLabourHoursTarget.toFixed(0)}H
//             </div>
//             {LabourHoursTarget.length > 0
//               ? LabourHoursTarget.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{x.toFixed(0)}H</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//           <div className="DataTable">
//             <div className="Item Total">
//             {TotalLabourHourLG.toFixed(0)}H
//             </div>
//             {LabourHourLG.length > 0
//               ? LabourHourLG.map((x) => (
//                   <>
//                     <div className="">-</div>
//                     <div className="Item">{x.toFixed(0)}H</div>
//                   </>
//                 ))
//               : ""}
//           </div>

//         </div>
//       </div>
//     </div>
//   );
// };

// export default LabourAnalysis;

import numeral from "numeral";
import { formatMonth, formatMonth1 } from "../../../utils/utils";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectTimeFilter } from "../../../reducers/AllApis";
import { InfoComp } from "./../../../utils/utils";

const LabourAnalysis = ({
  LabourInsightsHeader = [],
  TotalActualLabourRev = 0,
  ActualLabourRevArr = [],
  TotalActualLabourCost = 0,
  ActualLabourCostArr = [],
  TotalGrossPAL = 0,
  GrossPAL = [],
  TotalPerMargin = 0,
  PercentMargin = [],
  Time_Sheet = [],
  TotalForecastedLabourRevenue = 0,
  ForecastedLabourRevenue = [],
  TotalLabourRevenueVariance = 0,
  LabourRevenueVariance = [],
  TotalLabourRevenueVariancePer = 0,
  LabourRevenueVariancePer = [],
  TotalActualLabourHours = 0,
  ActualLabourHours = [],
  TotalLabourHoursTarget = 0,
  LabourHoursTarget = [],
  TotalLabourHourLG = 0,
  LabourHourLG = [],
  BudgetedLabourCostArr = [],
  TotalBudgetedLabourCost = 0,
  TotalEarnedValueArr = 0,
  EarnedValueArr = [],
  InvoicingTotalArr = [],
  TotalInvoicingTotalArr = 0,
}) => {
  const maxColumns = Math.max(
    LabourInsightsHeader.length,
    ActualLabourRevArr.length,
    ActualLabourCostArr.length,
    GrossPAL.length,
    PercentMargin.length,
    ForecastedLabourRevenue.length,
    LabourRevenueVariance.length,
    LabourRevenueVariancePer.length,
    ActualLabourHours.length,
    LabourHoursTarget.length,
    LabourHourLG.length,
    BudgetedLabourCostArr.length,
    EarnedValueArr.length,
    InvoicingTotalArr.length
  );

  const getClassName = (value, type) => {
    if (type === "percentage") {
      return Number(value) < 40 ? "reddata" : "green";
    }
    if (type === "variance") {
      return Number(value) < 0 ? "reddata" : "green";
    }
    return "";
  };

  function convertToActualValue(value) {
    if (typeof value === "string") {
      const cleanedValue = value.replace(/[^0-9.-]/g, "");
      return isNaN(cleanedValue) || cleanedValue === ""
        ? null
        : parseFloat(cleanedValue);
    }
    return value;
  }

  const renderRow = (
    dataArr = [],
    totalValue = "0",
    formatFn = null,
    isPercentage = false,
    isCurrency = true,
    type = null
  ) => {
    const actualValues = convertToActualValue(totalValue);

    return (
      <div className="DataTable">
        {/* <div className={`Item Total ${getClassName(actualValues, type)}`}>
          {totalValue}
        </div> */}
        {Array.from({ length: maxColumns }).map((_, i) => (
          <React.Fragment key={i}>
            {/* <div className=""></div> */}
            <div
              className={`Item ${
                dataArr[i] !== undefined &&
                dataArr[i] !== null &&
                !isNaN(Number(dataArr[i]))
                  ? getClassName(Number(dataArr[i]), type)
                  : ""
              }`}
            >
              {dataArr[i] !== undefined &&
              dataArr[i] !== null &&
              !isNaN(Number(dataArr[i]))
                ? formatFn
                  ? `${formatFn(Number(dataArr[i]))}`
                  : isPercentage
                  ? `${Number(dataArr[i]).toFixed(1)}`
                  : isCurrency
                  ? numeral(Number(dataArr[i])).format("0,0")
                  : `${Number(dataArr[i]).toFixed(0)}`
                : isPercentage
                ? "0"
                : isCurrency
                ? "0"
                : `0`}
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  };

  // const renderRow = (
  //   dataArr = [],
  //   totalValue = "0",
  //   formatFn = null,
  //   isPercentage = false,
  //   isCurrency = true,
  //   type = null
  // ) => (

  //   <div className="DataTable">
  //     <div className={`Item Total ${getClassName(totalValue, type)}`}>
  //       {totalValue}
  //     </div>
  //     {Array.from({ length: maxColumns }).map((_, i) => (
  //       <React.Fragment key={i}>
  //         <div className=""></div>
  //         <div
  //           className={`Item ${
  //             dataArr[i] !== undefined &&
  //             dataArr[i] !== null &&
  //             !isNaN(Number(dataArr[i]))
  //               ? getClassName(Number(dataArr[i]), type)
  //               : ""
  //           }`}
  //         >
  //           {dataArr[i] !== undefined &&
  //           dataArr[i] !== null &&
  //           !isNaN(Number(dataArr[i]))
  //             ? formatFn
  //               ? `${formatFn(Number(dataArr[i]))}`
  //               : isPercentage
  //               ? `${Number(dataArr[i]).toFixed(1)}`
  //               : isCurrency
  //               ? numeral(Number(dataArr[i])).format("0,0")
  //               : `${Number(dataArr[i]).toFixed(0)}`
  //             : isPercentage
  //             ? "0"
  //             : isCurrency
  //             ? "0"
  //             : `0`}
  //         </div>
  //       </React.Fragment>
  //     ))}
  //   </div>
  // );

  const dispatch = useDispatch();

  const detailspopupRef = useRef(null);
  const inputRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const detailstogglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      detailspopupRef.current &&
      !detailspopupRef.current.contains(event.target)
    ) {
      setIsPopupOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { SelectedTime } = useSelector((state) => state.allapi);
  const handleSelectTime = (data) => {
    dispatch(SelectTimeFilter(data));
  };

  return (
    <div className="LabourAnalysis labourcard ">
      {Time_Sheet.length > 0 ? (
        <div className="TableNew">
          <div className="TableFieldNames">
            <div className="TableStaticHeading">
              <div className="Item fixedheightheading">
                <div
                  className="detailsfilter"
                  onClick={detailstogglePopup}
                  ref={inputRef}
                  style={{ marginBottom: "-40px" }}
                >
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2212 18V16H14.2212V18H10.2212ZM6.22119 13V11H18.2212V13H6.22119ZM3.22119 8V6H21.2212V8H3.22119Z"
                      fill="currentColor"
                    />
                  </svg>
                  <p>
                    {SelectedTime === "monthly"
                      ? "Monthly"
                      : SelectedTime === "yearly"
                      ? "Yearly"
                      : SelectedTime === "quarterly"
                      ? "Quarterly"
                      : "View by"}
                  </p>

                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5427 8.95001L14.0227 15.47C13.2527 16.24 11.9927 16.24 11.2227 15.47L4.70267 8.95001"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {isPopupOpen && (
                    <div
                      className="graphpopup"
                      ref={detailspopupRef}
                      style={{
                        marginTop: "200px",
                        marginRight: "10px",
                      }}
                    >
                      <div className="popup-content">
                        <p onClick={(e) => handleSelectTime("monthly")}>
                          Monthly
                        </p>
                        <p onClick={(e) => handleSelectTime("yearly")}>
                          Yearly
                        </p>
                        <p onClick={(e) => handleSelectTime("quarterly")}>
                          Quarterly
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="Item">
                Invoicing Total ($) <InfoComp text="Total Revenue of Invoice" />
              </div>
              <div className="Item subitem">
                Actual Labour Revenue ($){" "}
                <InfoComp text="(Total Revenue of Invoice * ((Total Labour Chargeout / Total Project) * 100)) / 100" />
              </div>
              <div className="Item subitem">
                Actual Labour Cost ($){" "}
                <InfoComp text="-(Actual Labour Hours * Labour CTC/H)" />
              </div>
              <div className="Item subitem">
                Budgeted Labour Cost ($){" "}
                <InfoComp text="-(Labour Hours Target * Labour CTC/H)" />
              </div>
              <div className="Item subitem">
                Earned Value ($){" "}
                <InfoComp text="(Actual Labour Cost - Budgeted Labour Cost)" />
              </div>
              <div className="Item subitem">
                Gross Profit Attributable to Labour ($){" "}
                <InfoComp text="(Actual Labour Revenue + Actual Labour Cost)" />
              </div>
              <div className="Item subitem">
                % Margin{" "}
                <InfoComp text="(Gross Profit Attributable to Labour / Actual Labour Revenue) * 100" />
              </div>
              <div className="Item divider"></div>
              <div className="Item">
                Forecasted Labour Revenue ($){" "}
                <InfoComp text="(Total Hours * Labour Chargeout/H)" />
              </div>
              <div className="Item subitem">
                Labour Revenue Variance ($){" "}
                <InfoComp text="(Actual Labour Revenue - Forecasted Labour Revenue)" />
              </div>
              <div className="Item subitem">
                Labour Revenue Variance (%){" "}
                <InfoComp text="(Labour Revenue Variance/Forecasted Labour Revenue) * 100" />
              </div>
              <div className="Item divider"></div>
              <div className="Item">
                Actual Labour Hours (H) <InfoComp text="Total Hours" />
              </div>
              <div className="Item subitem">
                Labour Hours Target (H){" "}
                <InfoComp text="(Actual Labour Revenue/Labour Chargeout/H)" />
              </div>
              <div className="Item subitem">
                Labour Hour Loss / Gain (H){" "}
                <InfoComp text="Labour Hours Target - Actual Labour Hours" />
              </div>
              <div className="Item divider"></div>
            </div>
          </div>
          <div className="TableFieldData TableStaticField">
            <div className="DataTable Heading fixedheightheading">
              <div className="Item Total" style={{borderRight:"0"}}>
                <div className="First" style={{color: "#00000000"}}>hi</div>
                <div className="Second" style={{borderRight: "1px solid var(--item-total)"}}>Total</div>
              </div>
            </div>
            <div className="DataTable">
              <div className="Item Total">
               {numeral(TotalInvoicingTotalArr).format("0,0")}
              </div>
            </div>
            <div className="DataTable">
              <div className="Item Total">
                {numeral(TotalActualLabourRev).format("0,0")}
              </div>
            </div>
            <div className="DataTable">
              <div className="Item Total">
                {numeral(TotalActualLabourCost).format("0,0")}
              </div>
            </div>
            <div className="DataTable">
              <div className="Item Total">
              {numeral(TotalBudgetedLabourCost).format("0,0")}
              </div>
            </div>
            <div className="DataTable">
              <div className="Item Total">
              {numeral(TotalEarnedValueArr).format("0,0")}
              </div>
            </div>
            <div className="DataTable">
              <div className="Item Total">
              {numeral(TotalGrossPAL).format("0,0")}
              </div>
            </div>
            <div className="DataTable">
              <div className={`Item Total ${Number(TotalPerMargin) < 40 ? "reddata" : "green"}`} >
              {TotalPerMargin ? TotalPerMargin.toFixed(1) : "0"}
              </div>
            </div>
            <div className="dividertable"></div>
            <div className="DataTable">
              <div className="Item Total">
              {numeral(TotalForecastedLabourRevenue).format("0,0")}
              </div>
            </div>
            <div className="DataTable">
            <div className={`Item Total ${Number(TotalLabourRevenueVariance) < 0 ? "reddata" : "green"}`} >
              {numeral(TotalLabourRevenueVariance).format("0,0")}
              </div>
            </div>
            <div className="DataTable">
              <div className="Item Total">
              {TotalLabourRevenueVariancePer
                  ? TotalLabourRevenueVariancePer.toFixed(1)
                  : "0"}
              </div>
            </div>
            <div className="dividertable"></div>
            <div className="DataTable">
              <div className="Item Total">
              {TotalActualLabourHours ? TotalActualLabourHours.toFixed(0) : "0"}
              </div>
            </div>
            <div className="DataTable">
              <div className="Item Total">
              {TotalLabourHoursTarget ? TotalLabourHoursTarget.toFixed(0) : "0"}
              </div>
            </div>
            <div className="DataTable">
            <div className={`Item Total ${Number(TotalLabourHourLG) < 0 ? "reddata" : "green"}`} >
              {TotalLabourHourLG
                ? numeral(TotalLabourHourLG).format("0,0")
                : "0"}
              </div>
            </div>
            
      
          </div>
          <div className="TableFieldData TableFieldValues">
            <div className="DataTable Heading fixedheightheading">
              {/* <div className="Item Total">Total</div> */}
              {Array.from({ length: maxColumns }).map((_, i) => (
                <React.Fragment key={i}>
                  {/* <div className=""></div> */}
                  <div className="Item">
                    {LabourInsightsHeader[i] ? (
                      <>
                        <div className="First">
                          {formatMonth1(LabourInsightsHeader[i])}
                        </div>
                        <div className="Second">
                          {SelectedTime === "monthly"
                            ? "Month"
                            : SelectedTime === "yearly"
                            ? "Year"
                            : SelectedTime === "quarterly"
                            ? "Quarter"
                            : ""}{" "}
                          {i + 1}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
            {renderRow(
              InvoicingTotalArr,
              numeral(TotalInvoicingTotalArr).format("0,0")
            )}

            {renderRow(
              ActualLabourRevArr,
              numeral(TotalActualLabourRev).format("0,0")
            )}
            {renderRow(
              ActualLabourCostArr,
              numeral(TotalActualLabourCost).format("0,0")
            )}
            {renderRow(
              BudgetedLabourCostArr,
              numeral(TotalBudgetedLabourCost).format("0,0")
            )}
            {renderRow(
              EarnedValueArr,
              numeral(TotalEarnedValueArr).format("0,0")
            )}
            {renderRow(GrossPAL, numeral(TotalGrossPAL).format("0,0"))}
            {renderRow(
              PercentMargin,
              `${TotalPerMargin ? TotalPerMargin.toFixed(1) : "0"}`,
              null,
              true,
              true,
              "percentage"
            )}
            <div className="dividertable"></div>
            {renderRow(
              ForecastedLabourRevenue,
              numeral(TotalForecastedLabourRevenue).format("0,0")
            )}
            {renderRow(
              LabourRevenueVariance,
              numeral(TotalLabourRevenueVariance).format("0,0"),
              null,
              false,
              true,
              "variance"
            )}
            {renderRow(
              LabourRevenueVariancePer,
              `${
                TotalLabourRevenueVariancePer
                  ? TotalLabourRevenueVariancePer.toFixed(1)
                  : "0"
              }`,
              null,
              true
            )}
            <div className="dividertable"></div>
            {renderRow(
              ActualLabourHours,
              `${
                TotalActualLabourHours ? TotalActualLabourHours.toFixed(0) : "0"
              }`,
              null,
              false,
              "H",
              false
            )}

            {renderRow(
              LabourHoursTarget,
              `${
                TotalLabourHoursTarget ? TotalLabourHoursTarget.toFixed(0) : "0"
              }`,
              null,
              false,
              "H",
              false
            )}

            {renderRow(
              LabourHourLG,
              TotalLabourHourLG
                ? numeral(TotalLabourHourLG).format("0,0")
                : "0",
              null,
              false,
              true,
              "variance"
            )}
          </div>
        </div>
      ) : (
        <div className="ItemHead">No Data</div>
      )}
    </div>
  );
};

export default LabourAnalysis;
