
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import toast from 'react-hot-toast';

export const AddExcel = createAsyncThunk(
  'api/allexcel',
  async (credentials, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await axios.post(
        'https://quotech-uat-api.bravadagroup.com.au/api/report/quote-upload',
        credentials,
        config,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

let abortController;

export const UpdateKpi = createAsyncThunk(
  'api/updatekpi',
  async (payload, { rejectWithValue }) => {
    abortController = new AbortController();


    try {
      const IdToken = localStorage.getItem('IdToken');
      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
        signal: abortController.signal,
      };

      const response = await axios.put(
        'https://quotech-uat-api.bravadagroup.com.au/api/report/quote-upload/',
        payload,
        config,
      );


      return response.data;
    } catch (error) {
      if (error.name === 'AbortError') {

        return rejectWithValue('API call cancelled');
      } else {
        return rejectWithValue(error.message);
      }
    }
    finally {
      abortController.abort();
    }
  },
);

export const cancelUpload = () => {
  if (abortController) {

    abortController.abort();
  } else {

  }
};


export const ListExcelSearch = createAsyncThunk(
  'api/ListExcelSearch',
  async ({ search, page }, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };
      const url = search ? `https://quotech-uat-api.bravadagroup.com.au/api/report/job/?search=${search}` : `https://quotech-uat-api.bravadagroup.com.au/api/report/job/?page=${page}`;

      const response = await axios.get(url, config);
      sessionStorage.setItem(
        'ListExcel',
        JSON.stringify(response.data.results)
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);





export const ListExcel = createAsyncThunk(
  "api/listexcel",
  async (params, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem("IdToken");

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };


      const response = await axios.get(
        "https://quotech-uat-api.bravadagroup.com.au/api/report/job/",
        {
          ...config,
          params: params,
        }
      );

      sessionStorage.setItem("ListExcel", JSON.stringify(response.data.results));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);










export const POorderList = createAsyncThunk(
  'api/POorderList',
  async (userData, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.get(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/purchase-order/?job_id=${userData}`,
        config,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const QuotesList = createAsyncThunk(
  'api/QuotesList',
  async (userData, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.get(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/quote-sheet/${userData}`,
        config,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const UpdateJobtype = createAsyncThunk(
  'api/UpdateJobtype',
  async (userdata, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.patch(
        `https://quotech-uat-api.bravadagroup.com.au/report/job/${userdata.ID}`,
        {status: userdata.status},
        config,
      );




      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const GetProfile = createAsyncThunk(
  'api/GetProfile',
  async (userData, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.get(
        'https://quotech-uat-api.bravadagroup.com.au/api/auth/profile/',
        config,
      );
      localStorage.setItem('ProfileData', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const UpdateProfile = createAsyncThunk(
  'api/UpdateProfile',
  async (credentials, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.put(
        'https://quotech-uat-api.bravadagroup.com.au/api/auth/profile/',
        credentials,
        config,
      );
      localStorage.setItem('ProfileData', JSON.stringify(response.data));
      toast.success("Profile updated")
      return response.data;
    } catch (error) {
      toast.error("Something is wrong")

      return rejectWithValue(error.message);
    }
  },
);

export const ResetPass = createAsyncThunk(
  'api/ResetPass',
  async (credentials, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.put(
        'https://quotech-uat-api.bravadagroup.com.au/api/auth/change-password/',
        credentials,
        config,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);



export const ReportCSV = createAsyncThunk(
  'api/ReportCSV',
  async (search, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.get(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/reporting/`,
        config,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);






export const UpdateQuote = createAsyncThunk(
  'api/UpdateQuote',
  async (credentials, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.put(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/kpi-summaries-detail/${credentials.ID}`,
        credentials.FormData,
        config,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);




export const UpdateQuotesList = createAsyncThunk(
  'api/UpdateQuotesList',
  async (credentials, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.put(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/quote-sheet/${credentials.ID}`,
        credentials.FormData,
        config,
      );
      toast.success("Data updated")
      return response.data;
    } catch (error) {


      toast.error("Something is wrong")
      return rejectWithValue(error.message);
    }
  },
);










export const GetTimesheetData = createAsyncThunk(
  'api/GetTimesheetData',
  async (userData, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.get(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/timesheets/?data_type=${userData.type}&job_id=${userData.quote}`,
        config,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);


export const ProjectManagers = createAsyncThunk(
  'api/ProjectManagers',
  async (userData, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.get(
        'https://quotech-uat-api.bravadagroup.com.au/api/filters/',
        config,
      );
      return response.data.managers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);


export const Tags = createAsyncThunk(
  'api/Tags',
  async (search, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.get(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/tags/?search=${search}`,
        config,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);



export const AddTags = createAsyncThunk(
  'api/AddTags',
  async (credentials, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await axios.post(
        'https://quotech-uat-api.bravadagroup.com.au/api/report/tags/',
        credentials,
        config,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);


export const SiteList = createAsyncThunk(
  'api/SiteList',
  async (search, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.get(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/sites/?search=${search}`,
        config,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);




export const ClientList = createAsyncThunk(
  'api/ClientList',
  async (search, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.get(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/clients/?search=${search}`,
        config,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);



export const EmployeesListSales = createAsyncThunk(
  'api/EmployeesListSales',
  async (search, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.get(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/employees/?search=${search}`,
        config,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);


export const EmployeesListManager = createAsyncThunk(
  'api/EmployeesListManager',
  async (search, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.get(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/employees/?search=${search}`,
        config,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);


export const AddJob = createAsyncThunk(
  'api/AddJob',
  async (credentials, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await axios.post(
        'https://quotech-uat-api.bravadagroup.com.au/api/report/jobs-create/',
        credentials,
        config,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const UpdateJob = createAsyncThunk(
  'api/UpdateJob',
  async (credentials, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.patch(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/job/${credentials.id}`,
        credentials.formdata,
        config,
      );
      toast.success("Job updated")
      return response.data;
    } catch (error) {
      
      toast.error(error.response.data.detail)

      return rejectWithValue(error.message);
    }
  },
);


export const UserList = createAsyncThunk(
  'api/UserList',
  async (search, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.get(
        `https://quotech-uat-api.bravadagroup.com.au/api/auth/user/`,
        config,
      );
      return response.data.results;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);


export const VariationKPIList = createAsyncThunk(
  'api/VariationKPIList',
  async (id, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.get(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/variations/?job_id=${id}`,
        config,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);



export const ApproveVariationKPI = createAsyncThunk(
  'api/ApproveVariationKPI',
  async (credentials, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };
      const obj = {
        is_approve: credentials.approve
      }

      const response = await axios.patch(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/quote-sheet/${credentials.QuoteId}`,
        obj,
        config,
      );
    
      toast.success(response.data.detail)
      return response.data;
    } catch (error) {
      toast.error(error.response.data.detail)

      return rejectWithValue(error.response.data.detail);
    }
  },
);



export const AddVar = createAsyncThunk(
  'api/AddVar',
  async (credentials, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');
      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };
      const response = await axios.post(
        'https://quotech-uat-api.bravadagroup.com.au/api/report/variation-create/',
        credentials,
        config,
      );

      
      toast.success("Variation added")
      return response.data;
    } catch (error) {
   
      
      toast.error("Something is wrong")
      return rejectWithValue(error.message);
    }
  },
);


export const UpdateVar = createAsyncThunk(
  'api/UpdateVar',
  async (credentials, { rejectWithValue }) => {
    try {
      const IdToken = localStorage.getItem('IdToken');

      const config = {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      };

      const response = await axios.patch(
        `https://quotech-uat-api.bravadagroup.com.au/api/report/variations/?quote_id=${credentials.id}`,
        credentials.formdata,
        config,
      );
      toast.success("Variation updated")
      return response.data;
    } catch (error) {
      toast.error("Something is wrong")

      return rejectWithValue(error.message);
    }
  },
);





const initialState = {
  Success: false,
  UpdateJobtypeSuccess: false,
  Listdata: [],
  ListCount: false,
  ListdataSuccess: false,
  ListErr: false,
  ListSearchdata: [],
  ListSearchCount: false,
  ListSearchdataSuccess: false,
  ListSearchErr: false,
  theme: 'light',
  AddExcelErr: false,
  UpdateKpiErr: false,
  SuccessUpdate: false,
  POorderListData: [],
  QuotesListData: [],
  QuotesListDataErr: false,
  POorderListDataErr: false,
  GetProfileData: [],
  GetProfileErr: false,
  UpdateProfileSuccess: false,
  UpdateProfileError: false,
  UpdateProfileData: [],
  ResetPassSuccess: false,
  ResetPassErr: false,
  ReportData: [],
  ReportDataSuccess: false,
  ReportDataErr: false,
  UpdateQuoteSucess: false,
  UpdateQuoteErr: false,
  UpdateQuotesListSuccess: false,
  UpdateQuotesListErr: false,
  TimesheetData: [],
  TimesheetDataErr: false,
  TimesheetDataSuccess: false,
  OpenPopup: false,
  OpenJobPopup: false,
  ProjectManagersData: false,
  ProjectManagersSuccess: false,
  TagsData: [],
  TagsSuccess: false,
  SelectedTime: "monthly",
  AddTagsData: false,
  AddTagsSuccess: false,
  AddTagsErr: false,
  SiteListData: [],
  SiteListSuccess: false,
  ClientListData: [],
  ClientListSuccess: false,
  EmployeesListSalesData: [],
  EmployeesListSalesSuccess: false,
  EmployeesListManagerData: [],
  EmployeesListManagerSuccess: false,
  AddJobSuccess: false,
  AddJobErr: false,
  UserListData: [],
  UserListSuccess: false,
  StatusFilter: '',
  DateTypeFilter: '',
  StartDateFilter: '',
  EndDateFilter: '',
  TagsFilter: '',
  CategoryFilter: '',
  VariationKPIListData: "",
  VariationKPIListSuccess: false,
  ApproveVariationKPIData: false,
  ApproveVariationKPISuccess: false,
  ApproveVariationKPIErr: false,
  AddVarSuccess:false,
  AddVarErr: false,
  UpdateVarSuccess:false,
  UpdateJobSuccess:false,
  UpdateJobErr:false
};

const AllApis = createSlice({
  name: 'Apis',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.theme = state.theme === 'light' ? 'dark' : 'light';
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    ResetSuccessUpdate(state) {
      state.SuccessUpdate = false;
      state.UpdateProfileSuccess = false;
      state.UpdateProfileError = false;
      state.ResetPassSuccess = false;
      state.ResetPassErr = false;
      state.ReportDataSuccess = false;
      state.ReportDataErr = false;
      state.UpdateQuoteSucess = false;
      state.UpdateQuoteErr = false;
      state.AddTagsSuccess = false
      state.UpdateQuotesListSuccess = false;
      state.UpdateQuotesListErr = false;
      state.VariationKPIListSuccess = false;
        state.ApproveVariationKPIData = false;
        state.ApproveVariationKPISuccess = false;
        state.ApproveVariationKPIErr = false;
    },
    ResetTable(state) {
      state.POorderListData = [];
      state.QuotesListData = [];
      state.QuotesListDataErr = false;
      state.POorderListDataErr = false;
    },

    logoutAllApi(state) {
      state.Success = false;
      state.Listdata = [];
      state.ListErr = false;
      state.AddExcelErr = false;
      state.UpdateKpiErr = false;
      state.SuccessUpdate = false;
      state.POorderListData = [];
      state.QuotesListData = [];
      state.QuotesListDataErr = false;
      state.POorderListDataErr = false;
    },
    ActivePopup(state) {
      state.OpenPopup = true
    },
    InactivePopup(state) {
      state.OpenPopup = false
    },
    ActiveJobPopup(state) {
      state.OpenJobPopup = true
    },
    InactiveJobPopup(state) {
      state.OpenJobPopup = false
    },
    SelectTimeFilter(state, action) {
      state.SelectedTime = action.payload
    },
    StatusFilterFunc(state, action) {
      state.StatusFilter = action.payload;

    },
    DateTypeFilterFunc(state, action) {
      state.DateTypeFilter = action.payload;

    },
    StartDateFilterFunc(state, action) {
      state.StartDateFilter = action.payload;

    },
    EndDateFilterFunc(state, action) {
      state.EndDateFilter = action.payload;

    },
    TagsFilterFunc(state, action) {
      state.TagsFilter = action.payload;

    },
    CategoryFilterFunc(state, action) {
      state.CategoryFilter = action.payload;
    },


  },
  extraReducers: (builder) => {
    builder


    .addCase(UpdateJob.fulfilled, (state, action) => {
      state.UpdateJobSuccess = true;
    })
    .addCase(UpdateJob.rejected, (state, action) => {
      state.UpdateJobSuccess = 'Failed';
    })
    .addCase(UpdateJob.pending, (state, action) => {
      state.UpdateJobSuccess = 'Pending';
    })


    .addCase(UpdateVar.fulfilled, (state, action) => {
      state.UpdateVarSuccess = true;
    })
    .addCase(UpdateVar.rejected, (state, action) => {
      state.UpdateVarSuccess = 'Failed';
    })
    .addCase(UpdateVar.pending, (state, action) => {
      state.UpdateVarSuccess = 'Pending';
    })


    .addCase(AddVar.fulfilled, (state, action) => {
      state.AddVarSuccess = true;
    })
    .addCase(AddVar.rejected, (state, action) => {
      state.AddVarSuccess = 'Failed';
    })
    .addCase(AddVar.pending, (state, action) => {
      state.AddVarSuccess = 'Pending';
    })


      .addCase(ApproveVariationKPI.fulfilled, (state, action) => {
        state.ApproveVariationKPISuccess = true;
        state.ApproveVariationKPIData = action.payload.detail;
      })
      .addCase(ApproveVariationKPI.rejected, (state, action) => {
        state.ApproveVariationKPISuccess = 'Failed';
        state.ApproveVariationKPIErr = action.payload
      })
      .addCase(ApproveVariationKPI.pending, (state) => {
        state.ApproveVariationKPISuccess = 'Pending';
      })




      .addCase(VariationKPIList.fulfilled, (state, action) => {
        state.VariationKPIListSuccess = true;
        state.VariationKPIListData = action.payload;
      })
      .addCase(VariationKPIList.rejected, (state, action) => {
        state.VariationKPIListSuccess = 'Failed';
      })
      .addCase(VariationKPIList.pending, (state) => {
        state.VariationKPIListSuccess = 'Pending';
      })




      .addCase(UserList.fulfilled, (state, action) => {
        state.UserListSuccess = true;
        state.UserListData = action.payload;
      })
      .addCase(UserList.rejected, (state, action) => {
        state.UserListSuccess = 'Failed';


      })
      .addCase(UserList.pending, (state) => {
        state.UserListSuccess = 'Pending';
      })



      .addCase(AddJob.fulfilled, (state, action) => {
        state.AddJobSuccess = true;
      })
      .addCase(AddJob.rejected, (state, action) => {
        state.AddJobSuccess = 'Failed';
      })
      .addCase(AddJob.pending, (state, action) => {
        state.AddJobSuccess = 'Pending';
      })


      .addCase(EmployeesListSales.fulfilled, (state, action) => {
        state.EmployeesListSalesSuccess = true;
        state.EmployeesListSalesData = action.payload;
      })
      .addCase(EmployeesListSales.rejected, (state, action) => {
        state.EmployeesListSalesSuccess = 'Failed';


      })
      .addCase(EmployeesListSales.pending, (state) => {
        state.EmployeesListSalesSuccess = 'Pending';
      })



      .addCase(EmployeesListManager.fulfilled, (state, action) => {
        state.EmployeesListManagerSuccess = true;
        state.EmployeesListManagerData = action.payload;
      })
      .addCase(EmployeesListManager.rejected, (state, action) => {
        state.EmployeesListManagerSuccess = 'Failed';


      })
      .addCase(EmployeesListManager.pending, (state) => {
        state.EmployeesListManagerSuccess = 'Pending';
      })


      .addCase(ClientList.fulfilled, (state, action) => {
        state.ClientListSuccess = true;
        state.ClientListData = action.payload;
      })
      .addCase(ClientList.rejected, (state, action) => {
        state.ClientListSuccess = 'Failed';


      })
      .addCase(ClientList.pending, (state) => {
        state.ClientListSuccess = 'Pending';
      })


      .addCase(SiteList.fulfilled, (state, action) => {
        state.SiteListSuccess = true;
        state.SiteListData = action.payload;
      })
      .addCase(SiteList.rejected, (state, action) => {
        state.SiteListSuccess = 'Failed';


      })
      .addCase(SiteList.pending, (state) => {
        state.SiteListSuccess = 'Pending';
      })



      .addCase(AddTags.fulfilled, (state, action) => {
        state.AddTagsSuccess = true;
      })
      .addCase(AddTags.rejected, (state, action) => {
        state.AddTagsSuccess = 'Failed';
      })
      .addCase(AddTags.pending, (state, action) => {
        state.AddTagsSuccess = 'Pending';
      })



      .addCase(Tags.fulfilled, (state, action) => {
        state.TagsSuccess = true;
        state.TagsData = action.payload;
      })
      .addCase(Tags.rejected, (state, action) => {
        state.TagsSuccess = 'Failed';


      })
      .addCase(Tags.pending, (state) => {
        state.TagsSuccess = 'Pending';
      })




      .addCase(ProjectManagers.fulfilled, (state, action) => {
        state.ProjectManagersSuccess = true;
        state.ProjectManagersData = action.payload;
      })
      .addCase(ProjectManagers.rejected, (state, action) => {
        state.ProjectManagersSuccess = 'Failed';


      })
      .addCase(ProjectManagers.pending, (state) => {
        state.ProjectManagersSuccess = 'Pending';
      })





      .addCase(GetTimesheetData.fulfilled, (state, action) => {
        state.TimesheetDataSuccess = true;
        state.TimesheetData = action.payload;
      })
      .addCase(GetTimesheetData.rejected, (state, action) => {
        state.TimesheetDataSuccess = 'Failed';


      })
      .addCase(GetTimesheetData.pending, (state) => {
        state.TimesheetDataSuccess = 'Pending';
      })


      .addCase(UpdateQuotesList.fulfilled, (state) => {
        state.UpdateQuotesListSuccess = true;
      })
      .addCase(UpdateQuotesList.rejected, (state, action) => {
        state.UpdateQuotesListSuccess = 'Failed';
        state.UpdateQuotesListErr = action.payload;

      })
      .addCase(UpdateQuotesList.pending, (state) => {
        state.UpdateQuotesListSuccess = 'Pending';
      })



      .addCase(UpdateQuote.fulfilled, (state) => {
        state.UpdateQuoteSucess = true;
      })
      .addCase(UpdateQuote.rejected, (state, action) => {
        state.UpdateQuoteSucess = 'Failed';
        state.UpdateQuoteErr = action.payload;

      })
      .addCase(UpdateQuote.pending, (state) => {
        state.UpdateQuoteSucess = 'Pending';
      })



      .addCase(ReportCSV.fulfilled, (state, action) => {
        state.ReportData = action.payload;
        state.ReportDataSuccess = true;
      })
      .addCase(ReportCSV.rejected, (state, action) => {
        state.ReportDataSuccess = 'Failed';
        state.ReportDataErr = action.payload;

      })
      .addCase(ReportCSV.pending, (state) => {
        state.ReportDataSuccess = 'Pending';
      })



      .addCase(ResetPass.fulfilled, (state) => {
        state.ResetPassSuccess = true;
      })
      .addCase(ResetPass.rejected, (state, action) => {
        state.ResetPassSuccess = 'Failed';
        state.ResetPassErr = action.payload;
      })
      .addCase(ResetPass.pending, (state) => {
        state.ResetPassSuccess = 'Pending';
      })

      .addCase(UpdateProfile.fulfilled, (state, action) => {
        state.UpdateProfileSuccess = true;
        state.UpdateProfileData = action.payload;
      })
      .addCase(UpdateProfile.rejected, (state) => {
        state.UpdateProfileSuccess = 'Failed';
      })
      .addCase(UpdateProfile.pending, (state) => {
        state.UpdateProfileSuccess = 'Pending';
      })

      .addCase(GetProfile.fulfilled, (state, action) => {
        state.GetProfileData = action.payload;
      })
      .addCase(GetProfile.rejected, (state, action) => {
        state.GetProfileErr = action.payload;
      })

      .addCase(UpdateJobtype.fulfilled, (state, action) => {
        state.UpdateJobtypeSuccess = true;
      })
      .addCase(AddExcel.fulfilled, (state, action) => {
        state.Success = true;
      })
      .addCase(AddExcel.rejected, (state, action) => {
        state.Success = 'Failed';
        state.AddExcelErr = action.payload;
      })
      .addCase(AddExcel.pending, (state, action) => {
        state.Success = 'Pending';
      })


      .addCase(ListExcelSearch.fulfilled, (state, action) => {
        state.ListSearchdata = action.payload.results;
        state.ListCount = action.payload.count;
        state.ListSearchdataSuccess = true;
      })
      .addCase(ListExcelSearch.pending, (state, action) => {
        state.ListSearchdataSuccess = 'Pending';
      })
      .addCase(ListExcelSearch.rejected, (state, action) => {
        state.ListSearchErr = action.payload;
        state.ListSearchdataSuccess = false;
      })



      .addCase(ListExcel.fulfilled, (state, action) => {
        state.Listdata = action.payload.results;
        state.ListCount = action.payload.count;
        state.ListdataSuccess = true;
      })
      .addCase(ListExcel.pending, (state, action) => {
        state.ListdataSuccess = 'Pending';
      })
      .addCase(ListExcel.rejected, (state, action) => {
        state.ListErr = action.payload;
        state.ListdataSuccess = false;
      })
      .addCase(UpdateKpi.fulfilled, (state, action) => {
        state.SuccessUpdate = true;
      })
      .addCase(UpdateKpi.rejected, (state, action) => {
        state.SuccessUpdate = 'Failed';
        state.UpdateKpiErr = action.payload;
      })
      .addCase(POorderList.fulfilled, (state, action) => {
        state.POorderListData = action.payload;
      })
      .addCase(POorderList.rejected, (state, action) => {
        state.POorderListDataErr = action.payload;
      })
      .addCase(QuotesList.fulfilled, (state, action) => {
        state.QuotesListData = action.payload;
        state.QuotesListDataErr = false;
      })
      .addCase(QuotesList.pending, (state, action) => {
        state.QuotesListDataErr = 'Pending';
      })
      .addCase(QuotesList.rejected, (state, action) => {
        state.QuotesListDataErr = action.payload;
      });
  },
});

export const { ResetSuccessUpdate, ResetTable, logoutAllApi, toggleTheme, setTheme, ActivePopup, InactivePopup, ActiveJobPopup, InactiveJobPopup, SelectTimeFilter, StatusFilterFunc, DateTypeFilterFunc, StartDateFilterFunc, EndDateFilterFunc, TagsFilterFunc, CategoryFilterFunc } = AllApis.actions;
export default AllApis.reducer;
