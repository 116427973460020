import React, { useRef, useState, useEffect } from "react";

const InputFieldSearch = ({
  label,
  value,
  onChangeHandler,
  searchData,
  placeholder,
  setFormData,
  formKey,
}) => {
  const [selectedItem, setSelectedItem] = useState(null); 
  const [inputValue, setInputValue] = useState(value || "");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  

  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputValue(text);
    setSelectedItem(null); 
    onChangeHandler(e); 
    setIsPopupVisible(true);
  };

  const handleSelect = (item) => {
    
    setSelectedItem(item);
    setInputValue(item.name);
    setFormData((prevData) => ({
      ...prevData,
      [formKey]: item.id,
    }));
    setIsPopupVisible(false);
  };
  const ref = useRef()
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsPopupVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="InputDiv InputFieldSearch">
      <div className="Head">{label}</div>
      <div className="Input">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
        />
      </div>
      {isPopupVisible  && searchData.length > 0 && inputValue && !selectedItem && (
        <div className="SearchResults" ref={ref}>
          {searchData.map((x) => (
            <div
              key={x.id}
              onClick={() => handleSelect(x)}
              style={{ cursor: "pointer" }}
            >
              {x.name || x.tag}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default InputFieldSearch;
